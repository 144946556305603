
import React, { ReactNode } from 'react';
import './Button.scss';

const Button = (props : {
  children: ReactNode | string, 
  onClick?: () => void, 
  className?: string, 
  width?: string,
  ref?:  string,
  disabled?: boolean,
  secondary?: boolean,
}) => {
  return (
    <button
      type="button"
      ref={props.ref}
      onClick={props.onClick}
      disabled={props.disabled || false}
      className={`button ${props.className} ${props.secondary ? 'secondary' : 'primary'}`}
      style={{ width: props.width }}
    >
      { props.children }
    </button>
  )
}

export default Button;


