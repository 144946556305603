/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import  Dropdown  from 'react-select';
import './Select.scss';

const Select = (props: {
  data: any[], 
  onChange: (value: any) => void, 
  value: any, 
  placeholder?: string, 
  clearable?: true;
  className?: string;
}) => {

  const CustomStyle = {
    option: (base: any, state: { isDisabled: boolean; isSelected: boolean; }) => ({
      ...base,
      color: state.isDisabled ? '#999' : state.isSelected ? '#2a2d31' : '',
    }),
  };

  return (
    <div className={props.className}>
      <Dropdown
        options={props.data}
        value={props.value}
        isClearable={props.clearable}
        placeholder={props.placeholder}
        className="dropdownContainer"
        classNamePrefix="dropdown"
        styles={CustomStyle}
        onChange={(value ) => props.onChange(value)}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            neutral90: 'white',
            primary: 'white',
            primary25: '#2a2d31',
            neutral0: '#2a2d31',
          },
        })}
      />
    </div>
  );
};

Select.defaultProps = {
  placeholder: 'Select',
};

export default Select;
