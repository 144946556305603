import { configureStore } from '@reduxjs/toolkit';
import userState from './user.state';
import commonState from './common.state';
import usersOrgManagementState from './usersOrgManagement.state';
import plansState from './plans.state';
import modulesState from './modules.state';
import chargesState from './charges.state';
import usersCompany from './usersCompany.state';

export const store = configureStore({
  reducer: {
    user: userState.reducer,
    common: commonState.reducer,
    usersOrgManagement: usersOrgManagementState.reducer,
    usersCompany: usersCompany.reducer,
    plans: plansState.reducer,
    modules: modulesState.reducer,
    charges: chargesState.reducer,
  },
});

export type StoreType = ReturnType<typeof store.getState>;
