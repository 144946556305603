import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Charge } from '../types/Charge';
import { SimpleThunk } from '../types/SimpleTunk';
import Axios from '../utils/Axios';
import camelize from '../utils/camelize';


const initialStateCharges: Charge[] = [];

const charges = createSlice({
  name: 'charges',
  initialState: initialStateCharges,
  reducers: {
    addCharges(state, action: PayloadAction<Charge[]>) {
      return action.payload;
    },
  },
});

export const loadCharges = (): SimpleThunk => async (dispatch) => {
  console.info('get /modules');
  Axios.get('/charges')
    .then((rsp) => {
      console.info(rsp);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = rsp.data.data.map((charge: any) => camelize({ ...charge }));
      console.info(data);
      dispatch(charges.actions.addCharges(data));
    })
    .catch((err) => {
      console.info(err);
      toast.dismiss();
      toast.error('Error loading data', { autoClose: 3000 });
    });
};

export default charges;
