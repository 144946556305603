import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import ScrollBar from "../../components/ScrollBar/ScrollBar";
import Switcher from "../../components/Switcher/Switcher";
import userCompanyState, { createUser, deleteUser, loadUser , changeStatusUser, editUser } from "../../state/usersCompany.state";
import { ReactComponent as ArrowUp } from '../../static/icon/Arrow.svg'
import { UserOrganization } from "../../types/UserOrganization";
import { Organization } from "../../types/usersOrgManagement";
import { Charge } from "../../types/Charge";
import CreateEditUserModal from "./Modals/CreateEditUserModal";
import MessageModal from "./Modals/MessageModal";
import { StoreType } from "../../state/store";

const UserListComponent = (props: {
  organization?: Organization,
  listOrganization?: Organization[],
  listCharges?: Charge[],
}) => {
  const userState = useSelector((state: StoreType) => state.usersCompany);
  const users = useSelector((state: StoreType) => state.usersCompany.users);
  const [userList, setUserList] = useState<UserOrganization[]>(users);
  const [sortDirection, setSortDirection] = useState('asc');
  const [showModalUser, setShowModalUser] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [userMessageID, setUserMessageID] = useState<string>();
  const [typeMessageModal, setTypeMessageModal] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setUserList(users || []);
  }, [users]);

  const sortData = (key: 'firstName' | 'lastName' | 'type') => {
    const copyUserList = [...userList];
      if (sortDirection === 'asc') {
        setUserList(copyUserList.sort(
          (a, b) => a?.[key] > b?.[key] ? -1 : a[key]< b[key] ? 1 : 0
          ));
          setSortDirection('desc');
      } else{
        setUserList(copyUserList.sort(
          (a, b) => a?.[key] < b?.[key] ? -1 : a[key]> b[key] ? 1 : 0
          ));
          setSortDirection('asc');
      }
  }

  const deleteUserModal = (item: UserOrganization) => {
    setUserMessageID(item.id);
    setTypeMessageModal('delete');
    setShowMessageModal(true)
  }

  const changeStatus = (item: UserOrganization, checked: boolean) => {
    if (props.organization?.id) {
      dispatch(changeStatusUser({ ...item, status: checked ? 'ACTIVE' : 'INACTIVE' }, () => setShowModalUser(false)))
    }
  }

  const newUser = () => {
    dispatch(userCompanyState.actions.resetUserSelected());
    setShowModalUser(true)
  }

  return (
    <div className="user-list-content" >
      <div className="label">USER LIST</div>
      <div className="content-table-user">
        <ScrollBar >
          <table >
            <thead>
              <tr>
                <th onClick={() => sortData('firstName')}>Name<ArrowUp className={sortDirection}/></th>
                <th onClick={() => sortData('lastName')}>Surname<ArrowUp className={sortDirection}/></th>
                <th onClick={() => sortData('type')}>type<ArrowUp className={sortDirection}/></th>
                <th onClick={() => sortData('type')}>status<ArrowUp className={sortDirection}/></th>
                <th style={{ width: '220px' }}/>
              </tr>
            </thead>
            <tbody>
              { userList.length > 0 ? userList.map((item : UserOrganization, key) => 
                  <tr key={`item-user-${key}`}>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.typeUser}</td>
                    <td>{item.status}</td>
                    <td>
                      <div className="table-buttons">
                        <Switcher checked={item.status === 'ACTIVE'} onChange={ (value: boolean) => changeStatus(item, value)}/>
                        <Button width="70px" onClick={() => {
                          dispatch(loadUser(item?.id || ''))
                          setShowModalUser(true);
                        }}>Edit</Button>
                        <Button 
                          width="70px" 
                          secondary
                          onClick={() => deleteUserModal(item)}
                        >
                          Delete
                        </Button>
                      </div>
                    </td>
                 </tr>
                ) : <tr>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>
              }
            </tbody>
          </table>
        </ScrollBar>
      </div>
      <div className="content-button">
        <Button 
          secondary 
          onClick={() => newUser()}
          className={!props.organization?.name  ? 'disabled' : ''}
          disabled={!props.organization?.name ? true : false}
        > 
          New User
        </Button>
      </div>
      < CreateEditUserModal 
        showModal={showModalUser}
        closeModal={() => setShowModalUser(false)}
        user={userState.userSelected || { id: '', firstName: '', lastName: '', user: '', email: '' , type: '', status: '', password: '' } }
        organization={props.organization}
        listOrganization={props.listOrganization}
        listCharges={props.listCharges}
        confirm={(value: UserOrganization) => {
          if (props.organization?.id) {
            dispatch(
              userState.userSelected?.id ?
                editUser(value, props.organization?.id, () => setShowModalUser(false)) :
                createUser(value, props.organization?.id, () => setShowModalUser(false))
              )
          }
        }}
      />
      <MessageModal 
        showModal={showMessageModal}
        closeModal={() => setShowMessageModal(false)}
        text={typeMessageModal === 'delete' ? 'Are you sure you want to delete this user?' : 'The user has been activated.'}
        confirm={() => {
          if (props.organization?.id) {
            dispatch(
              deleteUser(userMessageID || '', () => setShowMessageModal(false)))
          }
        }}  
      />
    </div>
  );
};

export default UserListComponent