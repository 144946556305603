import React from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Sidebar  from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import Topbar from '../../components/Topbar/Topbar';
import HomePage from '../homePage/HomePage';
import PlanManagementPage from '../planManagementPage/PlanManagementPage';
import PrivateRoute from '../../components/Router/ProtectedRoute';
import User from '../../state/user.state'
import UserManagementPage from '../userManagementPage/UserManagementPage';
import './MainPage.scss';
import CreateCustomPack from '../planManagementPage/CreateCustomPack';

const  MainPage = () => {
  const dispatch = useDispatch();

  return (
    <div className="Main">
        <Sidebar/>
        <div className="principal-content">
          <Navbar >
            <div className='item' onClick={() => dispatch(User.actions.logout('true'))}>Log out</div>
          </Navbar>
          <Topbar/>
          <div className='content-pages'>
              <Switch>
                <PrivateRoute exact path="/">
                  <HomePage/>
                </PrivateRoute>
                <PrivateRoute  exact path="/home">
                  <HomePage />
                </PrivateRoute>
                <PrivateRoute  exact path="/plan-management">
                  <PlanManagementPage/>
                </PrivateRoute>
                <PrivateRoute exact path="/plan-management/create-custom-pack">
                  <CreateCustomPack />
                </PrivateRoute>
                <PrivateRoute path="/user-org-management">
                  <UserManagementPage />
                </PrivateRoute>
                <PrivateRoute path="*">
                  <p>There's nothing here!</p>
                </PrivateRoute>
              </Switch>
          </div>
        </div>
    </div>
  );
}

export default MainPage;