import { snakeCase } from 'lodash';

const convertToSnakeCase = (obj: any) => {
  if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
    const data = {};

    Object.keys(obj).forEach((k) => {
      // @ts-ignore
      data[snakeCase(k.toString())] = convertToSnakeCase(obj[k]);
    });

    return data;
  }
  return obj;
};

export default convertToSnakeCase;
