import React from 'react';
import './Card.scss';

const Card = (props: {title: string, text: string, img?: string, width?: string, onClick?: () => void}) => {
  return (
    <div className='card' onClick={props.onClick} >
        <div className='title'>{props.title}</div>
        <div className='text'>{props.text}</div>
        {props.img && <img src={props.img} alt={props.img}/>}
    </div>
  );
}

export default Card;
