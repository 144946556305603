import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../../components/Card/Card';
import iconUser from '../../static/icon/Users-Icon.svg';
import iconPlan from '../../static/icon/Plans-Icon.svg';
import './HomePage.scss';
import Switcher from '../../components/Switcher/Switcher';

const HomePage = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const history = useHistory();

  return (
    <div className='home-page' >
      <div className="content"> 
        <div className='home-title'>
          BITACORE ADMIN PANEL
        </div>
        <div className="description">
          Welcome  to  BITACore's  Administration  Panel,  here  you'll  be  able  to  modify  general  
          aspects  of  Us er  and  Organizations  accounts,  assign  BITACore's  plans  to  organization, 
          manage company trials and put the system into maintenance mode.
        </div>
        <div className='content-card'>
          <Card 
            title='USER/ORG MANAGEMENT' 
            text='Create and management of BITACore users and organization and assignment of plans.' 
            onClick={() => history.push('/user-org-management')}
            img={iconUser}
          />
          <Card 
            title='PLAN MANAGEMENT' 
            text='Manage BITACore product plans and custom features and options to guide the user experience' 
            img={iconPlan}
            onClick={() => history.push('/plan-management')}

          />
        </div>
      </div>
      <div className='content'>
        <div className='maintenance-mode'>
          <div className='title'>MAINTENANCE MODE :</div>
          <div>{maintenanceMode ? 'Deactivated' : 'Activated'}</div>
          <Switcher 
            secondary 
            checked={maintenanceMode} 
            onChange={ (value: boolean) => setMaintenanceMode(value)}
          />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
