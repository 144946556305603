import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Button from '../../components/Button/Button';
import ScrollBar from '../../components/ScrollBar/ScrollBar';
import { StoreType } from '../../state/store';
import usersOrgManagementState, { changeStatusOrganization, loadOrganization, loadSelectOrganization } from '../../state/usersOrgManagement.state';
import { ReactComponent as ArrowUp } from '../../static/icon/Arrow.svg'
import { ReactComponent as IconTitle } from '../../static/icon/Icon-title.svg';
import CreateUserOrgSection from './CreateUserOrgSection';
import './UserManagementPage.scss';
import { loadPlans } from '../../state/plans.state';
import { loadCharges } from '../../state/charges.state';

const UserManagementPage = () => {
  const organizations = useSelector((state: StoreType) => state.usersOrgManagement.usersOrganizations);
  const organizationSelected = useSelector((state: StoreType) => state.usersOrgManagement.organizationSelected);
  const plans = useSelector((state: StoreType) => state.plans.plans);
  const charges =  useSelector((state: StoreType) => state.charges);
  const [searchName, setSearchName] = useState('');
  const [showCreateEditOrg, setShowCreateEditOrg] = useState(false);
  const [listOrganization, setListOrganization] = useState(organizations);
  const [sortDirection, setSortDirection] = React.useState('asc');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadOrganization());
    dispatch(loadPlans());
    dispatch(loadCharges());
  }, [dispatch]);

  useEffect(() => {
    setListOrganization(
      organizations.filter((item) =>
        item.name?.toLowerCase().includes(searchName.toLowerCase())
      )
    );
  }, [organizations, searchName]);

  const sortData = (key: 'name' | 'plan' | 'expirationAccountTrial') => {
    if(key === 'expirationAccountTrial'){
      if (sortDirection === 'asc') {
        setListOrganization(listOrganization.sort(
          (a, b) => new Date(a?.[key]) > new Date(b?.[key]) ? -1 : new Date(a[key]) < new Date(b[key]) ? 1 : 0
          ));
          setSortDirection('desc');
        }
        else{
        setListOrganization(listOrganization.sort(
          (a, b) => new Date(a?.[key]) < new Date(b?.[key]) ? -1 : new Date(a[key]) > new Date(b[key]) ? 1 : 0
          ));
          setSortDirection('asc');
        }
    } else {
      if (sortDirection === 'asc') {
        setListOrganization(listOrganization.sort(
          (a, b) => a?.[key] > b?.[key] ? -1 : a[key]< b[key] ? 1 : 0
          ));
          setSortDirection('desc');
      } else{
        setListOrganization(listOrganization.sort(
          (a, b) => a?.[key] < b?.[key] ? -1 : a[key]> b[key] ? 1 : 0
          ));
          setSortDirection('asc');
      }
    }
  }

  const editOrganization = (id: string) => {
    dispatch(loadSelectOrganization(id, () =>  setShowCreateEditOrg(true)));
   
  }

  const namePlan = (name:string) => {
    const newName =  plans?.find(item => item.id === name)?.name;
    if (newName === 'BASIC'){
      return 'trial'
    }
    if (newName === 'COMPLETE'){
      return 'premium'
    }
    if (newName !== 'trial' && name !== 'core' && name !== 'premium' && name !== 'Demo' && name !== "None"){
      return 'custom'
    }
    return newName;
  }

  return (
    <div className='user-management-page' >
      <div className='content-widget'>
        <div className={`sub-widget ${showCreateEditOrg ? 'disabled' : ''}`}>
          <div>
            CREATE NEW ORGANIZATION 
          </div>
          <Button onClick={() => setShowCreateEditOrg(true)}>Create</Button>
        </div>
        {showCreateEditOrg && <CreateUserOrgSection 
          plans={plans}
          organization={organizationSelected} 
          close={() => {
            dispatch(usersOrgManagementState.actions.resetSelectedUserOrg());
            setShowCreateEditOrg(false);
          }}
          listOrganization={organizations}
          listCharges={charges}
        /> }
        <div className={`content-list ${showCreateEditOrg ? 'disabled' : ''}`} >
          <div className='header-list'>
            <div className='title'>
              <IconTitle />
              LIST OF ORGANIZATION
            </div>
            <input
              type="text"
              id="searchName"
              className='searchInput'
              placeholder="Search by ..."
              value={searchName}
              onChange={(value)=> setSearchName(value.target.value)}
            />
          </div>

            <div className='content-table'>
              <ScrollBar>
                <div className='table'>
                  <div className='head-table'>
                    <div onClick={() => sortData('name')}>Company Name<ArrowUp className={sortDirection}/></div>
                    <div onClick={() => sortData('plan')}>Plan <ArrowUp className={sortDirection}/></div>
                    <div  onClick={() => sortData('expirationAccountTrial')}>Expiration Date <ArrowUp className={sortDirection}/></div>
                    <div />
                  </div>
                  <div className='body-table'>
                    {
                      listOrganization.length > 0 && listOrganization.map((item, key) => 
                        <div className='row' key={`item-organization-${key}`}>
                          <div>{item.name}</div>
                          <div><div className={`plan-button ${namePlan(item.plan)}`}>{namePlan(item.plan)}</div></div>
                          <div><strong>Expiration Date: </strong>{dayjs(item.expirationAccountTrial).format('YYYY-MM-DD')}</div>
                          <div className='content-button'>
                            <Button onClick={() => {item?.id && editOrganization(item.id)}}>Edit</Button>
                            <Button 
                              disabled={item?.id === '5e293897a425972f28446fd7' }
                              secondary 
                              onClick={() => item.id && 
                                dispatch(changeStatusOrganization({ id: item.id, status: item.status !== 'ACTIVE' }))}
                              >{item.status === 'ACTIVE' ? 'Deactivate' : 'Activate'}</Button>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </ScrollBar>
            </div>
        </div>
      </div>
    </div>
  );
}

export default UserManagementPage;
