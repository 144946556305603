import React from "react";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import { ReactComponent as BitaIcon } from '../../../static/icon/iconBita.svg';
import './Modals.scss';

const MessageModal= (props: {
  showModal: boolean, 
  closeModal: () => void, 
  text?: string;
  confirm?: () => void
}) => {

  return (
    <Modal 
      isOpen={props.showModal} 
      setModalState={() => props.closeModal()} 
      background
    >
      <div className="message-modal">
        <div className="header">
          <BitaIcon /> 
          USER MANAGEMENT
        </div>
        <div className="text-description">
          {props.text}
        </div>
        <div className="content-buttons">
          <Button width="140px" onClick={props.confirm}>Yes</Button>
          <Button onClick={props.closeModal} width="140px">No</Button>
        </div>
      </div>
    </Modal>
  );
};

export default MessageModal;
