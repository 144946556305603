import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { StoreType } from '../../state/store';
import { login } from '../../state/user.state';
import logo from '../../static/img/BITA.svg';
import './LoginPage.scss';

const LoginPage = () => {
  const token = useSelector((state: StoreType) => state.user.token);
  const [user, setUser] = React.useState('');
  const [password, setPassword] = React.useState('');
  const usernameRef =  React.useRef<HTMLInputElement>(null);
  const pwRef =  React.useRef<HTMLInputElement>(null);
  const buttonRef =  React.useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleKeyDown = (keyValue: string) => {
    if (keyValue === 'Enter') {
      if (user.length && password.length && buttonRef && buttonRef.current) {
        buttonRef.current.focus()
      } else if (user.length && pwRef && pwRef.current) pwRef.current.focus();
      else usernameRef && usernameRef.current && usernameRef.current.focus();
    }
  };

  useEffect(() => {
    if (token && token !== '') {
      history.push('/home');
    }
  }, [token, history]);

  const Login = () => {
    if (user === '') {
      toast('Please Enter a User');
      return ;
    }
    if (password === '') {
      toast('Please Enter a Password');
      return;
    }
    dispatch(login({ user, password }))
  }
    
  return (
    <div className='login'>
      <div className='content-login'>
        <img src={logo} alt="BitaCore" className='logo'  />
        <div className='form'>
          <input
            type="text"
            id="username"
            placeholder="Email"
            value={user}
            onChange={(value)=> setUser(value.target.value)}
            ref={usernameRef}
            onKeyPress={evt => handleKeyDown(evt.key)}

          />
          <div className='row-password'>
            <input  
              type="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(value)=> setPassword(value.target.value)}
              onKeyPress={evt => handleKeyDown(evt.key)}
              ref={pwRef}
            />
            <a
              className='forgot'
              href="mailto:coreproject@bitadata.com?subject=Password Retrieval"
            >
              Forgot?{' '}
            </a>
          </div>
          <div >
            <input
              type="submit"
              id="loginBtn"
              className='button'
              value='Login'
              onClick={Login}
              ref={buttonRef}
              onKeyPress={evt => alert('login')}

            />
          </div>
        </div>
        <div className='separator'/>
        <div className='information'>
          <div >
            Don’t have a BITACore account? Contact our Sales team to discover our license pricing.
          </div>
          <div className='link'>bitacore@bitadata.com</div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
