import { BrandingColors } from "../../types/usersOrgManagement";

const DataPalettesDefault : BrandingColors  = {
    primaryChart: "#ff0808",
    secondaryChart: "#2962FF",
    tertiaryChart:"#FA5400",
    header: "#ffffff",
    gradientLeft: "#ffffff",
    gradientRight: "#ffffff",
  };

  export default DataPalettesDefault;