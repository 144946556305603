import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Module } from '../types/plans';
import { SimpleThunk } from '../types/SimpleTunk';
import Axios from '../utils/Axios';
import camelize from '../utils/camelize';


const initialStateModules: Module[] = [];

const modules = createSlice({
  name: 'modules',
  initialState: initialStateModules,
  reducers: {
    addModules(state, action: PayloadAction<Module[]>) {
      return action.payload;
    },
  },
});

export const loadModules = (): SimpleThunk => async (dispatch) => {
  console.info('get /modules');
  Axios.get('/modules')
    .then((rsp) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = rsp.data.modules.map((module: any) => camelize({ ...module }));
      dispatch(modules.actions.addModules(data));
    })
    .catch((err) => {
      console.info(err);
      toast.dismiss();
      toast.error('Error loading data', { autoClose: 3000 });
    });
};

export default modules;
