export const customStylesContent = {
  top: '50%',
  left: '50%',
  maxHeight: '95%',
  minHeight: '150px',
  minWidth: '300px',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  background: 'rgb(41, 41, 42)',
  color: '#ffffff',
  border: 'none',
  borderRadius: '15px',
}
