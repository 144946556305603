/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import ReactCrop , { Crop } from 'react-image-crop';
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import { getCroppedImg } from '../lib/cropImage';
import { ReactComponent as BitaIcon } from '../../../static/icon/iconBita.svg';
import 'react-image-crop/dist/ReactCrop.css';
import './Modals.scss';

const BrandingLogoModal = (props: {
  showModal: boolean, 
  closeModal: () => void, 
  logo?: string,
  confirm: (value: Blob) => void,
}) => {
  const [companyLogo, setLogo] = useState<string>('');
  const [files, setFiles] = useState<any>();
  const imageInputRef = React.useRef<HTMLInputElement>(null); 
  const [imageRef, setImageRef] = useState<EventTarget & HTMLImageElement>();
  const [blob, setBlob] = useState(null);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    width: 340,
    height: 210,
    x: 0,
    y: 0,
  })

  useEffect(() => {
    setLogo('');
    setLogo(props.logo || '');
  }, [props.showModal]);

  useEffect(() => {
    if (files) {
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = () => {
        setLogo(reader?.result?.toString() || '');
      };
      reader.onerror = error => {
        console.info('Error converting file ', error);
      };
    }
  }, [files]);

  const getBlob = (blobs:any) => {
    setBlob(blobs);
  };
  
  const makeClientCrop = async (cropSelect: Crop) => {
    if (imageRef && cropSelect.width && cropSelect.height) {
      const croppedImageUrl = await getCroppedImg(imageRef, cropSelect);
      getBlob(croppedImageUrl);
    }
  };
  const onCropComplete = (cropSelect: Crop) => {
    makeClientCrop(cropSelect);
  };

  return (
    <Modal 
      isOpen={props.showModal} 
      setModalState={() => props.closeModal()} 
      background
    >
      <div className="branding-logo-modal">
        <div className="header">
          <BitaIcon /> 
          COMPANY LOGO
        </div>
        <div className="content-modal">
          <div className="text-description">
            Please upload a “.SVG” or “.PNG” that complices with the following aspects:
            <ul>
              <li>Transparent Background</li>
              <li>Size of 120 x 55 px</li>
            </ul>
          </div>
          <div className="content-image-crop">
            <div className="input-file-container">
              <input
                type="file"
                onChange={event => {
                  setFiles(event?.target?.files?.[0]);
                }}
                className='input-file'
                name="logoFile"
                id="logoFile"
                ref={imageInputRef}
                accept=".png,.svg"
              />
              <label htmlFor="logoFile">Upload Logo</label>
            </div>
            <div className="preview-content">
              <div>Logo</div>
              <div className="logo-preview-container">
                { companyLogo &&
                <ReactCrop 
                  crop={crop} 
                  onChange={c => setCrop(c)} 
                  onComplete={onCropComplete}

                > 
                  <img
                    alt="Crop me"
                    src={companyLogo}
                    onLoad={event => {
                      setImageRef(event.currentTarget);
                      makeClientCrop(crop)
                    }}
                  />
                </ReactCrop>}
              </div>
            </div>
          </div>
          <div className="content-buttons">
            <Button onClick={() => {
              blob && props.confirm(blob)
              props.closeModal();
            }}>Confirm</Button>
            <Button 
              secondary       
              onClick={() => props.closeModal()} 
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default  BrandingLogoModal