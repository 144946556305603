/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { StoreType } from '../../state/store';

const PrivateRoute = (props: any) => {
  const { children, ...rest } = props;
  const token = useSelector((state: StoreType) => state.user.token);
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
      token && token !== '' ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;