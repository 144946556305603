import axios, { AxiosInstance } from 'axios';

console.info(`URL BASE ES ${process.env.REACT_APP_API_URL}`);

interface AxiosInstanceRocket extends AxiosInstance {
  setToken?: (token: string) => void;
}

const Axios: AxiosInstanceRocket = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

Axios.setToken = (token) => {
  Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default Axios;
