import { camelCase } from 'lodash';

const camelize = (obj: any) => {
  if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
    const data = {};

    Object.keys(obj).forEach((k) => {
      // @ts-ignore
      data[camelCase(k.toString())] = camelize(obj[k]);
    });

    return data;
  }
  return obj;
};

export default camelize;
