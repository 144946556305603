import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../components/Button/Button';
import ScrollBar from '../../components/ScrollBar/ScrollBar';
import { StoreType } from '../../state/store';
import PlanState, { enablePlan, deletePlan, loadPlans, loadSelectPlan } from '../../state/plans.state';
import { ReactComponent as ArrowUp } from '../../static/icon/Arrow.svg'
import { ReactComponent as IconTitle } from '../../static/icon/Icon-title.svg';
import MessageModal from './MessageModal';
import './PlanManagementPage.scss';

const PlanManagementPage = () => {
  const organizations = useSelector((state: StoreType) => state.usersOrgManagement.usersOrganizations);
  const plans = useSelector((state: StoreType) => state.plans.plans);
  const [searchName, setSearchName] = useState('');
  const [listOrganization, setListOrganization] = useState(organizations);
  const [listPlans, setListPlans] = useState(plans);
  const [sortDirection, setSortDirection] = useState('asc');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [planSelected, setPlanSelected] = useState<{id: string, status: boolean}>();
  const [typeMessage, setTypeMessage] = useState('status');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
      dispatch(loadPlans());
  }, [dispatch]);

  useEffect(() => {
    setListPlans(
      plans.filter((item) =>
        item.name.toLowerCase().includes(searchName.toLowerCase())
      )
    );
  }, [plans, searchName]);

  const sortData = (key: 'name' ) => {
    const copyUserList = [...listOrganization];
    if (sortDirection === 'asc') {
      setListOrganization(copyUserList.sort(
        (a, b) => a?.[key] > b?.[key] ? -1 : a[key]< b[key] ? 1 : 0
        ));
        setSortDirection('desc');
    } else{
      setListOrganization(copyUserList.sort(
        (a, b) => a?.[key] < b?.[key] ? -1 : a[key]> b[key] ? 1 : 0
        ));
        setSortDirection('asc');
    }
  }

  const confirmMessagePlan = () => {
    if (typeMessage === 'Deactivate'){
      planSelected && dispatch(deletePlan(planSelected.id,  () => setShowMessageModal(false)));
      return;
    }
    planSelected && dispatch(enablePlan(planSelected.id, () => setShowMessageModal(false)));
  }

  const returnMessage = () => {
    if (planSelected?.status) {
      return 'Are you sure you want to activate this plan?' ;
    }
    return  'Are you sure you want to inactive this plan?';
  }

  return (
    <div className='plan-management-page' >
      <div className='content-widget'>
        <div className="sub-widget">
          <div>
            NEW CUSTOM PLAN
          </div>
          <Button onClick={() => {
            dispatch(PlanState.actions.resetSelectedPlan());
            history.push('/plan-management/create-custom-pack');}}>Create</Button>
        </div>
        
        <div className={`content-list`} >
          <div className='header-list'>
            <div className='title'>
              <IconTitle />
              STANDARD PACKS
            </div>
            <input
              type="text"
              id="searchName"
              className='searchInput'
              placeholder="Search by ..."
              value={searchName}
              onChange={(value)=> setSearchName(value.target.value)}
            />
          </div>
          <div className="subtitle" >Plans List</div>

          <div className='content-table'>
            <ScrollBar>
              <table >
                <thead>
                  <tr>
                    <th onClick={() => sortData('name')}>Name<ArrowUp className={sortDirection}/></th>
                    <th className='header-button-status' />
                  </tr>
                </thead>
                <tbody>
                  {listPlans.map((item, key) => 
                      <tr key={`item-user-${key}`}>
                        <td>
                          <div 
                            className={`plan-button ${item?.name}`}
                            onClick={() => {
                              item?.id && dispatch(loadSelectPlan(item?.id.toString(), () =>  history.push('/plan-management/create-custom-pack')));
                            }}
                          >
                            {item?.name}
                          </div>
                        </td>
                        <td>
                          <div className="table-buttons">
                            <Button 
                              onClick={() => {
                                item?.id && dispatch(loadSelectPlan(item?.id.toString(), () =>  history.push('/plan-management/create-custom-pack')));
                              }}
                            >
                                Edit
                            </Button>
                            <Button 
                              secondary 
                              onClick={ () => {
                                item.id && setPlanSelected({ id: item.id, status: item.status  !== 'ACTIVE' });
                                setTypeMessage(item.status  === 'ACTIVE' ? 'Deactivate' : 'Activate');
                                setShowMessageModal(true)
                                }}
                              > {item.status  === 'ACTIVE' ? 'Deactivate' : 'Activate'}
                            </Button>
                          </div>
                        </td>
                    </tr>
                    ) 
                  }
                </tbody>
              </table>
            </ScrollBar>
          </div>
          <MessageModal 
            showModal={showMessageModal}
            closeModal={() => setShowMessageModal(false)}
            text={returnMessage()}
            confirm={confirmMessagePlan}  
          />
        </div>
      </div>
    </div>
  );
}

export default PlanManagementPage;
