import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Axios from '../utils/Axios';
import { SimpleThunk } from '../types/SimpleTunk';
import { Customization, Organization, UsersOrgManagement } from '../types/usersOrgManagement';
import camelize from '../utils/camelize';
import convertToSnakeCase from '../utils/convertToSnakeCase';
import { loadUsersOrganization } from './usersCompany.state';
import { colors } from '../constant/palette';
const initialStateUsersOrg: UsersOrgManagement ={
  usersOrganizations: []
};

const usersOrgManagement = createSlice({
  name: 'usersOrgManagement',
  initialState: initialStateUsersOrg,
  reducers: {
    addUsersOrg(state, action: PayloadAction<Organization[]>) {
      return { ...state, usersOrganizations: action.payload };
    },
    addOrganization(state, action: PayloadAction<Organization>) {
      return { ...state, usersOrganizations: [...state.usersOrganizations, action.payload] };
    },
    addSelectedOrganization(state, action: PayloadAction<Organization>) {
      const selOrganization = state.usersOrganizations.find(
        (i) => i.id === action.payload.id
      );
      if (selOrganization) {
        state.organizationSelected= { ...selOrganization, ...action.payload };
      }
    },
    setInformationOrg(state, action: PayloadAction<Organization>)  {
      const listOrganization = state.usersOrganizations.map((organization) => {
        if (organization.id === action.payload.id) {
          organization = action.payload;
        }
        return organization;
      });
      return { ...state, usersOrganizations: listOrganization };
    },
    setStatusOrg(state, action: PayloadAction<{id: string, status: string}>)  {
      const selOrganization = state.usersOrganizations.find(
        (i) => i.id === action.payload.id
      );
      if (selOrganization) {
        selOrganization.status = action.payload.status;
      }
    },
    resetSelectedUserOrg(state, action: PayloadAction) {
      return { usersOrganizations: state.usersOrganizations };
    },
  },
});

export const loadOrganization = (): SimpleThunk => async (dispatch) => {
  console.info('get /companies');
  Axios.get('/companies')
    .then((rsp) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = rsp.data.companies.map((company: any) => camelize({ ...company }));
      dispatch(usersOrgManagement.actions.addUsersOrg(data));
    })
    .catch((err) => {
      console.info(err);
      toast.dismiss();
      toast.error('Error loading data', { autoClose: 3000 });
    });
};

export const loadSelectOrganization = (id: string, completeAction: () => void): SimpleThunk => async (dispatch) => {
  console.info('get /company/id');
  Axios.get(`/company/${id}`)
    .then((rsp) => {
      const data = camelize(rsp.data.company);
      dispatch(usersOrgManagement.actions.addSelectedOrganization({ ...data , plan: data.plan.name }));
        completeAction();
        dispatch(loadUsersOrganization(id));
    })
    .catch((err) => {
      console.info(err.response.data.message);
      toast.dismiss();
      if (err.response.data.message === "Cannot read property 'plan' of null"){
        toast.error('Active this Company to edit');
        return;
      }
      toast.error('Error loading data', { autoClose: 3000 });
    });
    

};

export const createOrganization = (data: Organization, completeAction: () => void): SimpleThunk => async (dispatch) => {
  console.info('post /organization');
  const body = convertToSnakeCase({ ...data, accountName: data.name.replace(/\s+/g, '_') });
  const bodyCustomer =  { 
    texts: {
      about: data.description || '',
      disclaimer: data.disclaimer || ''
    },
    colors: { ...colors , ...body.branding_colors }
  };
  Axios.post('/company', body)
    .then((rsp) => {
      const newCompany = camelize(rsp.data.company);
      toast.dismiss();
      toast.success(`created organization successfully!`);
      completeAction();
      dispatch(usersOrgManagement.actions.addOrganization(newCompany));
      dispatch(editCustomization(bodyCustomer,newCompany.id ));
    })
    .catch((err) => {
      console.info(err);
      toast.dismiss();
      toast.error('Error loading data', { autoClose: 3000 });
    });
};

export const editOrganization = (data: Organization, completeAction: () => void): SimpleThunk => async (dispatch) => {
  console.info('put /company/id');
  const body = convertToSnakeCase(data);
  const bodyCustomer =  { 
    texts: {
      about: data.description || '',
      disclaimer: data.disclaimer || ''
    },
    colors: { ...colors , ...body.branding_colors }
  };
  Axios.put(`/company/${data.id}`, body)
    .then((rsp) => {
      const newCompany = camelize(rsp.data.company);
      toast.dismiss();
      toast.success(`edit organization successfully!`);
      completeAction();
      dispatch(usersOrgManagement.actions.setInformationOrg(newCompany));
      dispatch(editCustomization(bodyCustomer, data.id || '' ));
    })
    .catch((err) => {
      console.info(err);
      toast.dismiss();
      toast.error('Error loading data', { autoClose: 3000 });
    });
};

export const changeStatusOrganization = (data :{id: string, status: boolean}): SimpleThunk => async (dispatch) => {
  console.info('patch /company/id');
  const url = data.status ? `/company/${data.id}/enable` : `/company/${data.id}`;
  Axios.patch( url)
    .then((rsp) => {
      toast.dismiss();
      toast.success(`change status company successfully!`);
      dispatch(usersOrgManagement.actions.setStatusOrg({ id: data.id, status: data.status ? 'ACTIVE' : 'INACTIVE' }))
    })
    .catch((err) => {
      console.info(err);
      toast.dismiss();
      toast.error('Error loading data', { autoClose: 3000 });
    });

};

export const editCustomization = (data: Customization, idCustomization: string): SimpleThunk => async (dispatch) => {
  console.info('patch /company/id', data);
  Axios.post(`/custom-company/${idCustomization}`, data)
    .then((rsp) => {
      console.info(rsp);
      const newCompany = camelize(rsp.data.message);
      toast.dismiss();
      toast.success(`custom branding saved successfully!`);
      dispatch(usersOrgManagement.actions.addOrganization(newCompany));
    })
    .catch((err) => {
      console.info(err);
      toast.dismiss();
      toast.error('Failed to save branding data', { autoClose: 3000 });
    });
};

export default usersOrgManagement;
