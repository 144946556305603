import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Button from '../../components/Button/Button';
import Switcher from '../../components/Switcher/Switcher';
import { createPlan, deletePlan, editPlan } from '../../state/plans.state';
import { StoreType } from '../../state/store';
import { ReactComponent as IconTitle } from '../../static/icon/Icon-title.svg';
import './PlanManagementPage.scss';
import MessageModal from './MessageModal';
import { loadModules } from '../../state/modules.state';
import { Module } from '../../types/plans';

const CreateCustomPack = () => {
  const plan = useSelector((state: StoreType) => state.plans.planSelected);
  const modules = useSelector((state: StoreType) => state.modules);
  const [planName, setPlanName] = useState(plan?.name || '');
  const [backtestLimit, setBacktestLimit] = useState('0');
  const [listSettings, setListSettings] = useState<Module[]>(plan?.modules|| []);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadModules());
  }, [dispatch]);

  const changeStatus = (module: Module) => {
    const copyModules = [...listSettings];
    if (copyModules.find(item => item.name === module.name)?.id) {
      const newModules = copyModules.map(item => {
        if (item.name === module.name){
          return module
        }
        return item;
      }) 
      setListSettings(newModules);
      return;
    }

    setListSettings([...copyModules, module]);
  }

  const savePlan = () => {
    if (planName === ''){
      toast('Please Enter a Plan Name!');
      return;
    }
    if (plan?.id){
      dispatch(editPlan({ ...plan, name: planName, modules: listSettings, backtestingLimit: Number(backtestLimit) }, () => history.push('/plan-management')));
      return;
    }
    dispatch(createPlan({ name: planName, modules: listSettings, status: 'active' }, () => history.push('/plan-management')))
  }
  const resetPlan = () => {
    setPlanName(plan?.name || '');
    setListSettings(plan?.modules  || []);
  }

  const returnStatus = (id: string) => {
    return listSettings.find(item => item.id === id)?.statusPlan
  }

  return (
    <div className='plan-management-page' >
      <div className='title-plan-management'>
        <IconTitle /> Create Custom pack
      </div>

      <div className='content-widget'>
        <div className='content-title'>
          <div>{plan?.name || 'Untitled Plan'} </div>
          <div>
            <Button onClick={savePlan}>Save</Button>
            <Button secondary onClick={resetPlan}>Reset</Button>
            <Button secondary onClick={() => history.push('/plan-management')}>Close</Button>
          </div>
        </div>
        <div className='form-company'>
          <div className='row'>
            <div className='label'>PLAN NAME</div>
            <input
              type="text"
              placeholder="Release Plan"
              value={planName}
              onChange={(value)=> setPlanName(value.target.value)}
            />
          </div>
          {
            modules.map((item, key) => <div className='row'  key={`item-${key}`}>
              <div className='item-switch'>
                <div className='item'>{item.name}</div>
                <Switcher 
                  checked={returnStatus(item.id) === "ACTIVE"} 
                  onChange={ (value: boolean) => changeStatus({ ...item, statusPlan: value ? 'ACTIVE' : 'INACTIVE' })}
                />
              </div>
            </div> )
          }   
          <div className='row'>
              <div className='item-switch'>
                <div className='item'>BACKTEST LIMITE</div>
                <input
                  type="text"
                  placeholder=""
                  className='input-amount'
                  value={backtestLimit}
                  onChange={(value)=> setBacktestLimit(value.target.value)}
                />

              </div>
            </div>    
        </div>
        <div className='content-buttons'>
          <Button 
            className='button-apply'
            onClick={savePlan}
          > 
            Apply
          </Button>
          <Button 
            disabled={!plan?.id}
            secondary
            className={`button-apply ${!plan?.id ? 'disabled' : ''}`}
            onClick={() => setShowMessageModal(true)}
          > 
            Delete
          </Button>
        </div>
      </div>
      <MessageModal 
        showModal={showMessageModal}
        closeModal={() => setShowMessageModal(false)}
        text={'Are you sure you want to delete this plan?'}
        confirm={() => 
          plan?.id && 
            dispatch(deletePlan(plan?.id,  () => {
              history.push('/plan-management');
              setShowMessageModal(false);
            }))}  
      />
    </div>
  );
}

export default CreateCustomPack;
