import React from 'react';
import Switch from 'react-switch';

const Switcher = (props: {
  checked: boolean, 
  onChange: (value: boolean) => void, 
  disabled?: boolean, 
  secondary: boolean
}) => {
  return (
      <Switch
        disabled={props.disabled}
        onChange={props.onChange}
        handleDiameter={13.7}
        offColor="#fff"
        onColor={props.secondary ? "#FA5400" : "#0039CB"}
        offHandleColor={props.secondary ? "#FA5400" : "#0039CB"}
        onHandleColor="#fff"
        uncheckedIcon={false}
        checkedIcon={false}
        height={20}
        width={40}
        activeBoxShadow="0px 0px 2px 2px rgba(0, 0, 0, 0.2)"
        checked={props.checked}
      />
  );
};

Switcher.defaultProps = {
  secondary: false,
};

export default Switcher;