import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { SimpleThunk } from '../types/SimpleTunk';
import { UserCompany, UserOrganization } from '../types/UserOrganization';
import Axios from '../utils/Axios';
import camelize from '../utils/camelize';
import convertToSnakeCase from '../utils/convertToSnakeCase';

const initialStateUsers: UserCompany = {
  users: []
};

const usersCompany = createSlice({
  name: ' usersCompany',
  initialState: initialStateUsers,
  reducers: {
    addUsers(state, action: PayloadAction<UserOrganization[]>)  {
      return { ...state, users: action.payload };
    },
    addUser(state, action: PayloadAction<UserOrganization>)  {
      return { ...state, users: [...state.users, action.payload] };
    },
    addUserSelected(state, action: PayloadAction<UserOrganization>)  {
      return { ...state, userSelected: action.payload };
    },
    setUserInformation(state, action: PayloadAction<UserOrganization>)  {
      const users = state.users?.map(
        (i) => {
          if (i.id === action.payload.id){
            return action.payload
          }
          return i;
        }
      );
      return { ...state, users: users, userSelected: action.payload };
    },
    deleteUser(state, action: PayloadAction<string>)  {
      const listUser = state.users?.filter(
        (i) => i.id !== action.payload
      );
      return { ...state, users: listUser };
    },
    changeStatusUser(state, action: PayloadAction< UserOrganization>)  {
      const selUser = state.users?.find(
        (i) => i.id === action.payload.id
      );
      if (selUser){
        selUser.status = action.payload.status || 'ACTIVE';
      }
    },
    resetUserSelected(state, action: PayloadAction) {
      return { users: state.users };
    },
  },
});

export const loadUsersOrganization = (id: string): SimpleThunk => async (dispatch) => {
  dispatch(usersCompany.actions.addUsers([]));
  Axios.get(`/users/${id}`)
    .then((rspUser) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataUsers = rspUser.data.users.map((user: any) => camelize(user));
      dispatch(usersCompany.actions.addUsers(dataUsers));
    })
    .catch((err) => {
      console.info(err);
      if (err.response.data.message ===  'Company not found'){
        return;
      }
      toast.dismiss();
      toast.error('Error loading data', { autoClose: 3000 });
    });
};

export const loadUser = (
  idUser: string
): SimpleThunk => async (dispatch) => {
console.info('post /user');
  Axios.get(`/user/${idUser}`)
  .then((rsp) => {
    console.info(rsp);
    const data = camelize(rsp.data.employer);
    dispatch(usersCompany.actions.addUserSelected({ ...data, charge: data.charge.id }));
  })
  .catch((err) => {
    console.info(err);
    toast.dismiss();
    toast.error('Error loading data', { autoClose: 3000 });
  });
};

export const createUser = (
  data: UserOrganization,
  idOrganization: string, 
  completeAction: () => void
): SimpleThunk => async (dispatch) => {
console.info('post /user');
const body = convertToSnakeCase({ ...data, company: idOrganization });
Axios.post('/user-by-admin', body)
  .then((rsp) => {
    const user = camelize(rsp.data.employer);
    toast.dismiss();
    toast.success(`create User successfully!`)
    completeAction();
    dispatch(usersCompany.actions.addUser({ ...user, status: "ACTIVE" }));
  })
  .catch((err) => {
    console.info(err.response);
    if (err.response.data.email === 'The email is in use'){
      toast.error(err.response.data.email);
      return;  
    }
    toast.dismiss();
    toast.error('Error loading data', { autoClose: 3000 });
  });
};

export const editUser = (
  data: UserOrganization,
  idOrganization: string,  
  completeAction: () => void
): SimpleThunk => async (dispatch) => {
  console.info('put /user');
  const body = convertToSnakeCase({ ...data, company: idOrganization });
  Axios.put(`/user/${data.id}`, body)
  .then((rsp) => {
    console.info(rsp);
    const newUser = camelize(rsp.data.employer);
    toast.dismiss();
    toast.success(`Edit User successfully!`);
    completeAction();
    dispatch(usersCompany.actions.setUserInformation(newUser));
  })
  .catch((err) => {
    console.info(err);
    toast.dismiss();
    toast.error('Error loading data', { autoClose: 3000 });
  });

};

export const changeStatusUser = (
  data: UserOrganization,
  completeAction: () => void
): SimpleThunk => async (dispatch) => {
  console.info('patch /user');
  const url = data.status === 'ACTIVE' ? `user/${data.id}/enable` : `user/${data.id}/disable`;
  Axios.patch(url)
  .then((rsp) => {
    toast.dismiss();
    toast.success(rsp.data.message);
    completeAction();
    dispatch(usersCompany.actions.changeStatusUser(data));
  })
  .catch((err) => {
    console.info(err);
    toast.dismiss();
    toast.error('Error loading data', { autoClose: 3000 });
  });

};

export const deleteUser = (
  userId: string,
  completeAction: () => void
): SimpleThunk => async (dispatch) => {
  console.info('delete /user');
  Axios.delete(`user/${userId}`)
  .then((rsp) => {
    toast.dismiss();
    toast.success(rsp.data.message);
    completeAction();
    if (rsp.data.message === 'User deleted successfully'){
      dispatch(usersCompany.actions.deleteUser(userId));
    }
  })
  .catch((err) => {
    console.info(err);
    toast.dismiss();
    toast.error('Error loading data', { autoClose: 3000 });
  });

};


export default  usersCompany;
