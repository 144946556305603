import React, { useEffect, useState } from "react";
import ReactColorPicker from '@super-effective/react-color-picker';
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import { ReactComponent as BitaIcon } from '../../../static/icon/iconBita.svg';
import { BrandingColors } from "../../../types/usersOrgManagement";
import './Modals.scss';

const BrandingPaletteModal = (props: {
  showModal: boolean, 
  closeModal: () => void, 
  palettes: BrandingColors,
  paletteSelected?: string,
  confirm: (value: {title: string, color: string}) => void
}) => {
  const [tabSelected, setTabSelected] = useState(props.paletteSelected || Object.keys(props.palettes)[0]);
  const [newColor, setNewColor ] = useState(props.palettes[tabSelected]);

  useEffect(() => {
    props.paletteSelected && setTabSelected(props.paletteSelected)
  }, [props.paletteSelected]);

  const saveColor = () => {
    props.confirm({ title: tabSelected, color: newColor });
    props.closeModal();
  }

  return (
    <Modal 
      isOpen={props.showModal} 
      setModalState={() => props.closeModal()} 
      background
    >
      <div className="branding-palette-modal">
        <div className="header">
          <BitaIcon /> 
          COMPANY COLORS
        </div>
        <div className="content-tabs-colors">
          {Object.keys(props.palettes)?.map((palette: string) => 
            <div 
              className={tabSelected === palette ? 'tab-selected' : ''} 
              key={palette} 
              onClick={() => setTabSelected(palette)}
            >
              {palette}
            </div>
          )}
        </div>
        <div className="text-description">
          This color will apply to Main titles, charts, dividers, color of text header 
          in pages 2 & 3 and positive exposures of Style Factor Tilts chart in Report.
        </div>
        <div className="content-color-picker">
          <ReactColorPicker color={props.palettes[tabSelected]} onChange={(value: string) => setNewColor(value)} />
        </div>
        <div className="content-buttons">
          <Button onClick={() => saveColor()}>Confirm</Button>
          <Button 
            secondary 
            onClick={() => props.closeModal()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BrandingPaletteModal;
