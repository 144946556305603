export const  menuData = [
    { 
      name: 'HOME', 
      link: '/home',  
      textTopBar: 'HOME' 
    }, 
    { 
      name: 'USER/ORG MANAGEMENT', 
      link: '/user-org-management', 
      textTopBar: 'USER/ORG MANAGEMENT'
    },
    { 
      name: 'PLAN MANAGEMENT', 
      link: '/plan-management', 
      textTopBar: 'CUSTOM PLAN MANAGEMENT' 
    },
  ];

