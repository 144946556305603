import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import Select from "../../../components/Select/Select";
import ScrollBar from "../../../components/ScrollBar/ScrollBar";
import { Organization } from "../../../types/usersOrgManagement";
import { Plan } from "../../../types/plans";
import { ReactComponent as BannerTrial } from '../../../static/banner/BannerTrial.svg';
import { ReactComponent as BannerCore } from '../../../static/banner/BannerCore.svg';
import { ReactComponent as BannerPremium } from '../../../static/banner/BannerPremium.svg';
import { ReactComponent as BitaIcon } from '../../../static/icon/iconBita.svg';
import './Modals.scss';

const SelectedPlanModal= (props: {
  showModal: boolean, 
  closeModal: () => void, 
  organization?: Organization,
  listOrganization?: Organization[],
  plans?: Plan[],
  confirm: (plan: string , organization: string) => void
}) => {
  const [organizationSelected, setOrganizationSelected] = useState({ 
    value: props?.organization?.id?.toString() || '', 
    label: props?.organization?.name || '' 
  });
  const [planSelected, setPlanSelected] = useState(props.organization?.plan || 'None');
  const dataOrganization = (props?.listOrganization || []).map((org) => ({
    label: org?.name?.toString(),
    value: org?.id?.toString(),
  }));

  const dataPlans = (props?.plans|| []).map((plan) => ({
    label: plan?.name?.toString(),
    value: plan?.id?.toString(),
  }));

  return (
    <Modal 
      isOpen={props.showModal} 
      setModalState={() => props.closeModal()} 
      background
    >
      <div className="select-plan-modal">
        <div className="header">
          <BitaIcon /> 
          AFFILIATE ORGANIZATION TO PLAN
        </div>
        <div style={{ height: 'calc(95vh - 100px)', maxHeight: '620px' }}>
        <ScrollBar>
        <div className="content-select-plan">
          {
            props.organization?.plan !== 'None'  &&     
            <div className='row'>
              <div className='label'>COMPANY NAME</div>
              <Select 
                data={dataOrganization} 
                value={organizationSelected} 
                onChange={(value) => setOrganizationSelected(value)}
              />
            </div>
          }
          <div className='row'>
            <div className='label'>PLAN</div>
            <div className="content-plans">
              <div className="plan-option">
                <input
                  type='radio'
                  name="plan"
                  id="BASIC"
                  checked={planSelected === 'BASIC'}
                  onChange={e => {setPlanSelected('BASIC')}}
                />
                <label htmlFor="BASIC"><BannerTrial /></label>
              </div>
              <div className="plan-option">
                <input
                  type='radio'
                  name="plan"
                  id="core"
                  checked={planSelected === 'core'}
                  onChange={e => {setPlanSelected('core')}}
                />
                <label htmlFor="core"><BannerCore /></label>

                
              </div>
              <div className="plan-option">
                <input
                  type='radio'
                  name="plan"
                  id="COMPLETE"
                  checked={planSelected === 'COMPLETE'}
                  onChange={e => {setPlanSelected('COMPLETE')}}
                />
                <label htmlFor="COMPLETE"><BannerPremium /></label>

                
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='label'>CUSTOM PLAN</div>
            <Select 
              placeholder="Select Plan" 
              data={dataPlans} 
              value={dataPlans.find(plan => plan.label === planSelected)}
              onChange={(value) => setPlanSelected(value.label)}
            />
          </div>
          <div className="content-buttons">
            <Button secondary className="disabled">New Custom Plan</Button>
            <Button secondary className="disabled">Edit Custom Plan</Button>
          </div>
          <Button 
            onClick={() => {
              props.closeModal();
              props.confirm(planSelected,  organizationSelected.value)}}
          >
            Confirm
          </Button>
        </div>
        </ScrollBar>
        </div>
      </div>
    </Modal>
  );
};

export default SelectedPlanModal