import React from 'react';
import { Provider } from 'react-redux';
import { store } from "./state/store";
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MainPage from './pages/mainPage/MainPage';
import LoginPage from './pages/loginpage/LoginPage';
import './scss/App.scss';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/login"  component={LoginPage}/>
          <Route path="/" component={MainPage} />
        </Switch>
      </Router>
      <ToastContainer
          position="top-right"
          autoClose={6000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
        />
      </Provider>
    </div>
  );
}

export default App;
