import React, { useState } from 'react';
import { Link } from "react-router-dom";
import logo from '../../static/img/BITACore-Background.svg';
import { menuData } from '../../constant/Menu';
import './Sidebar.scss';

const Sidebar = () => {
  const [itemSelected, setItemSelected] = useState('');

  return (
    <div className='sidebar'>
      <img src={logo} alt="BitaCore" className='logo'  />
      <div className='menu'>
        {menuData.map((item, key) => 
        <Link to={item.link} key={`sidebar-item-link-${key}`}        >
          <div 
            className={`item ${item.name === itemSelected ? 'selected' : ''}`} 
            onClick={() => setItemSelected(item.name)}
            key={`sidebar-item-${key}`}
          >
          {item.name}
            
          </div>

          </Link>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
