import React, { ReactNode } from 'react';
// @ts-ignore
import CustomScroll from 'react-custom-scroll';
import 'react-custom-scroll/dist/customScroll.css';

const ScrollBar = (props: { children: ReactNode }) => {
  return (
    <CustomScroll
      allowOuterScroll={true}
      heightRelativeToParent="100%"
      className="custom-scrollbars"
    >
      <div className="content-scrollbar">{props.children}</div>
    </CustomScroll>
  );
};

export default ScrollBar;