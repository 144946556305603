import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import ScrollBar from "../../../components/ScrollBar/ScrollBar";
import Select from "../../../components/Select/Select";
import { ReactComponent as BitaIcon } from '../../../static/icon/iconBita.svg';
import { Charge } from "../../../types/Charge";
import { UserOrganization } from "../../../types/UserOrganization";
import { Organization } from "../../../types/usersOrgManagement";
import { GeneratePassword } from "../lib/GeneratePassword";
import './Modals.scss';

const CreateEditUserModal= (props: {
  showModal: boolean, 
  closeModal: () => void, 
  user?: UserOrganization,
  organization?: Organization,
  listOrganization?: Organization[],
  listCharges?: Charge[],
  confirm?: (value: UserOrganization) => void
}) => {
  const [firstName, SetFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [typeUser, setTypeUser] = useState('');
  const [chargeSelected, setChargeSelected] = useState({ 
    value: props.listCharges?.find(item => item.id === props.user?.charge)?.id || '', 
    label: props.listCharges?.find(item => item.id === props.user?.charge)?.name || '' 
  });
  const [organizationSelected, setOrganizationSelected] = useState({ 
    value: props.organization?.id?.toString() || '', 
    label: props.organization?.name || '' 
  });

  const dataOrganization = (props?.listOrganization || []).map((org) => ({
    label: org?.name?.toString(),
    value: org?.id?.toString(),
  }));

  const dataCharges = (props?.listCharges|| []).map((org) => ({
    label: org?.name?.toString(),
    value: org?.id?.toString(),
  }));

  useEffect(() => {
    SetFirstName(props.user?.firstName || '');
    setSurname(props.user?.lastName|| '');
    setUsername(props.user?.user|| '');
    setEmail(props.user?.email|| '');
    setTypeUser(props.user?.typeUser|| '');
    setPassword(props.user?.password || '');
    setChargeSelected({ 
      value: props.listCharges?.find(item => item.id === props.user?.charge)?.id || '', 
      label: props.listCharges?.find(item => item.id === props.user?.charge)?.name || '' 
    })
  }, [props.user]);

  const copyPassword = () =>{
    if(navigator.clipboard) {
      navigator.clipboard.writeText(password).then(() => {
        toast("Copy Password is ready!")
      })
    } else {
      toast('Browser Not compatible')
    }
  }

  const validateData = () => {
    if(organizationSelected.value === ''){
      toast("The Company Name is a mandatory input field, please provide the required info.")
      return false;
    }
    if(firstName === ''){
      toast("The First Name is a mandatory input field, please provide the required info.")
      return false;
    }
    if(surname === ''){
      toast("The Surname is a mandatory input field, please provide the required info.")
      return false;
    }
    if(email === ''){
      toast("The Email is a mandatory input field, please provide the required info.")
      return false;
    }
    if(typeUser === ''){
      toast("The Type is a mandatory input field, please provide the required info.")
      return false;
    }
    if(password === '' && !props.user?.id){
      toast("The Password is a mandatory input field, please provide the required info.")
      return false;
    }
    return true
  }

  const saveUser = () => {
    if(validateData()){
      const newUser: UserOrganization = { 
        ...props.user, 
        firstName: firstName, 
        lastName: surname, 
        email, 
        charge: chargeSelected.value,
        type: typeUser, 
        password
      };
      if (username) {
        newUser.user = username;
      }
      props.confirm && props.confirm(newUser);
    }
  }

  return (
    <Modal 
      isOpen={props.showModal} 
      setModalState={() => props.closeModal()} 
      background
    >
      <div className="user-modal">
        <div className="header">
          <BitaIcon /> 
          {props.user?.name ? 'EDIT' : 'CREATE NEW'} USER
        </div>
        <div className="content-modal">
          <ScrollBar>
            <div className='form-company'>
              <div className='row'>
                <div className='label'>AFFILIATED TO:</div>
                <Select 
                  data={dataOrganization} 
                  value={organizationSelected} 
                  onChange={(value) => setOrganizationSelected(value)}
                  className="disabled"
                />
              </div>

              <div className='row'>
                <div className='label'>FIRST NAME</div>
                <input
                  type="text"
                  placeholder="Insert Name (no special characters)... "
                  value={firstName}
                  onChange={(value)=> SetFirstName(value.target.value)}
                />
              </div>

              <div className='row'>
                <div className='label'>SURNAME</div>
                <input
                  type="text"
                  placeholder="Insert Surame (no special characters)... "
                  value={surname}
                  onChange={(value)=> setSurname(value.target.value)}
                />
              </div>

              <div className='row'>
                <div className='label'>USER NAME</div>
                <input
                  type="text"
                  placeholder="Insert User Name.... (e.g. jackdoe, etc.) "
                  value={username}
                  onChange={(value)=> setUsername(value.target.value)}
                />
              </div>

              <div className='row'>
                <div className='label'>BUSINESS EMAIL </div>
                <input
                  type="text"
                  placeholder="Insert business email... (e.g. jack@company.com) "
                  value={email}
                  onChange={(value)=> setEmail(value.target.value)}
                />
              </div>

              <div className='row'>
                <div className='label'>PASSWORD</div>
                <input
                  type="text"
                  placeholder="-"
                  value={password}
                  onChange={(value)=> setPassword(value.target.value)}
                />
                <div className="content-button-password">
                  <Button 
                    secondary 
                    width="60px" 
                    onClick={() => setPassword(GeneratePassword(8))}
                  >
                    Generate
                  </Button>
                  <Button 
                    secondary 
                    width="60px" 
                    onClick={copyPassword}
                    disabled={password === ''}
                  >Copy</Button>
                </div>
              </div>

              <div className='row'>
                <div className='label'>CHARGE</div>
                <Select 
                  data={dataCharges} 
                  value={chargeSelected} 
                  onChange={(value) => setChargeSelected(value)}
                />
              </div>

              <div className='row'>
                <div className='label'>USER TYPE</div>
                <div className="content-ratio-options">
                  <div >
                    <input
                      type='radio'
                      name="type"
                      id='ADMIN'
                      checked={typeUser === 'ADMIN'}
                      onChange={e => setTypeUser('ADMIN')}
                    />
                    <label htmlFor='ADMIN'>
                      Admin
                    </label>
                  </div>
                  <div>
                    <input
                      type='radio'
                      name="type"
                      id="READ"
                      checked={typeUser === 'READ'}
                      onChange={e => setTypeUser('READ')}
                    />
                    <label htmlFor="READ">
                      Read
                    </label>
                  </div>
                </div>
              </div>

              <div className='row disabled'>
                <div className='label'>PROFILE IMAGE</div>
                <div>
                  <div className='content-preview'>
                    <div className='preview' />
                    <Button secondary width='120px'>Upload Photo</Button>
                  </div>
                </div>
              </div>
              <Button 
                width="200px" 
                onClick={() => saveUser()}
              >
                Confirm
              </Button>
            </div>
          </ScrollBar>
        </div>
      </div>
    </Modal>
  );
};

export default CreateEditUserModal;
