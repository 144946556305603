import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SimpleThunk } from '../types/SimpleTunk';
import { Plan, PlanState } from '../types/plans';
import { toast } from 'react-toastify';
import Axios from '../utils/Axios';
import camelize from '../utils/camelize';
import convertToSnakeCase from '../utils/convertToSnakeCase';

const initialStatePlans: PlanState = {
  plans: []
};

const plans = createSlice({
  name: 'plans',
  initialState: initialStatePlans,
  reducers: {
    addPlans(state, action: PayloadAction<Plan[]>) {
      return { ...state, plans: action.payload };
    },
    addPlan(state, action: PayloadAction<Plan>) {
      return { ...state, plans: [...state.plans, action.payload] };
    },
    addSelectedPlan(state, action: PayloadAction<Plan>) {
      return { ...state, planSelected: action.payload };
    },
    setInformationPlan(state, action: PayloadAction<Plan>)  {
      const listPlans = state.plans.map((plan) => {
        if (plan.id === action.payload.id) {
          plan = action.payload;
        }
        return plan;
      });
      return { ...state, plans: listPlans };
    },
    changeStatusPlan(state, action: PayloadAction<{id: string, status: boolean}>)  {
      const selPlan = state.plans.find(
        (i) => i.id === action.payload.id
      );
      if (selPlan){
        selPlan.status = action.payload.status ? 'ACTIVE' : 'INACTIVE';
      }
    },
    resetSelectedPlan(state, action: PayloadAction) {
      return { plans: state.plans };
    },
  },
});

export const loadPlans = (): SimpleThunk => async (dispatch) => {
  console.info('get /plans');
  Axios.get('/plans')
    .then((rsp) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = rsp.data.plans.map((plan: any) => camelize({ ...plan }));
      dispatch(plans.actions.addPlans(data));
    })
    .catch((err) => {
      console.info(err);
      toast.dismiss();
      toast.error('Error loading data', { autoClose: 3000 });
    });
};

export const loadSelectPlan = (id: string, completeAction: () => void): SimpleThunk => async (dispatch) => {
  console.info('get /plan/id');
  Axios.get(`/plan/${id}`)
    .then((rsp) => {
      const data = camelize(rsp.data.plan);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const modules = data.modules.map((module: any) => camelize(module));
      dispatch(plans.actions.addSelectedPlan({ ...data, modules: modules }));
      completeAction();
    })
    .catch((err) => {
      console.info(err);
      toast.dismiss();
      if (err.response.data.message === "Plan not found"){
        toast.error('Active this plan to edit');
        return;
      }
      toast.error('Error loading data', { autoClose: 3000 });
    });
};

export const createPlan = (data: Plan, completeAction: () => void): SimpleThunk => async (dispatch) => {
  console.info('post /plan');
  const idModules = data.modules?.map(item => item.id);
  const body = convertToSnakeCase({ ...data, modules: idModules, backtestingLimit: 3 });
  Axios.post('/plan', body)
    .then((rsp) => {
      const newPlan = camelize(rsp.data.plan);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const modules = newPlan.modules.map((module: any) => camelize(module));
      toast.dismiss();
      toast.success('created Plan successfully!');
      completeAction();
      dispatch(plans.actions.addPlan({ ...newPlan, modules }));
    })
    .catch((err) => {
      console.info(err);
      toast.dismiss();
      toast.error('Error loading data', { autoClose: 3000 });
    });

};

export const editPlan = (data: Plan, completeAction: () => void): SimpleThunk => async (dispatch) => {
  console.info('put /plan/id');
  const idModules = data.modules?.map(item => item.id);
  const body = convertToSnakeCase({ ...data, modules: idModules, backtestingLimit: 3 });
  Axios.put(`/plan/${body.id}`, body)
  .then((rsp) => {
    const newPlan = camelize(rsp.data.plan);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const modules = newPlan.modules.map((module: any) => camelize(module));
    toast.dismiss();
    toast.success(`edit Plan successfully!`);
    completeAction();
    dispatch(plans.actions.setInformationPlan({ ...newPlan, modules }));

  })
  .catch((err) => {
    console.info(err);
    toast.dismiss();
    toast.error('Error loading data', { autoClose: 3000 });
  });

};

export const enablePlan = (id: string, completeAction: () => void): SimpleThunk => async (dispatch) => {
  console.info('put /plan');
  Axios.patch(`/plan/${id}/enable`)
  .then((rsp) => {
    toast.dismiss();
    toast.success(rsp.data.message);
    completeAction();
    dispatch(plans.actions.changeStatusPlan({ id, status: true }));
  })
  .catch((err) => {
    console.info(err);
    toast.dismiss();
    toast.error('Error loading data', { autoClose: 3000 });
  });
};


export const deletePlan = (
  id: string, completeAction: () => void
  ): SimpleThunk => async (dispatch) => {
  console.info('delete /user');
  Axios.delete(`/plan/${id}`)
    .then((rsp) => {
      toast.dismiss();
      toast.success(rsp.data.message);
      completeAction();
      dispatch(plans.actions.changeStatusPlan({ id, status: false }));
    })
    .catch((err) => {
      console.info(err);
      toast.dismiss();
      toast.error('Error loading data', { autoClose: 3000 });
    });
};

export default plans;