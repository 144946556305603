import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DatePickerInput } from 'rc-datepicker';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Button from '../../components/Button/Button';
import PaletteItem from './PaletteItem';
import UserListComponent from './UserListComponent';
import SelectedPlanModal from './Modals/SelectedPlanModal';
import { createOrganization, editOrganization } from '../../state/usersOrgManagement.state';
import { Organization } from '../../types/usersOrgManagement';
import { Plan } from '../../types/plans';
import { Charge } from '../../types/Charge';
import BrandingLogoModal from './Modals/BrandingLogoModal';
import BrandingPaletteModal from './Modals/BrandingPaletteModal';
import DataPalettesDefault from './DataPalettesDefault';
import './UserManagementPage.scss';

const CreateUserOrgSection= (props: {
  organization?: Organization, 
  close: () => void,
  listOrganization?: Organization[],
  listCharges?: Charge[],
  plans?: Plan[],
}) => {
  const [companyName, setCompanyName] = useState(props.organization?.name || '');
  const [description, setDescription] = useState(props.organization?.customization?.texts?.about || '');
  const [disclaimer, setDisclaimer] = useState(props.organization?.customization?.texts?.disclaimer || '');
  const [address, setAddress] = useState(props.organization?.address);
  const [expirationDate, setExpirationDate] = useState( props.organization?.expirationAccountTrial);
  const [website, setWebsite] = useState(props.organization?.website);
  const [logo, setLogo] = useState(props.organization?.logo);
  const [plan, setPlan] = useState('None');
  const [colorPalette, setColorPalette] = useState('');
  const [showModalPlan, setShowModalPlan] = useState(false);
  const [showModalLogo, setShowModalLogo] = useState(false);
  const [showModalPalette, setShowModalPalette] = useState(false);
  const [brandingColor, setBrandingColor] = useState(props.organization?.customization?.colors || DataPalettesDefault);
  const dispatch = useDispatch();

  const namePlan = (name:string) => {
    if (name === 'BASIC'){
      return 'trial'
    }
    if (name === 'COMPLETE'){
      return 'premium'
    }
    if (name !== 'trial' && name !== 'core' && name !== 'premium' && name !== 'Demo' && name !== "None"){
      return 'custom'
    }
    return name;
  }

  const save = () => {
    if (companyName !== '' && expirationDate && plan !== 'None' && props.organization?.id !== '5e293897a425972f28446fd7') {
      props.organization?.id ? 
        dispatch(editOrganization({
          ...props.organization,
          name: companyName,
          plan: props.plans?.find(item => item.name === plan)?.id?.toString() || '',
          description,
          expirationAccountTrial: dayjs(expirationDate).format('YYYY-MM-DD'),
          disclaimer,
          website,
          brandingColors: brandingColor,
          address: address || '',
          logo: logo || '',
        }, () => props.close())) :
        dispatch(createOrganization({
          name: companyName,
          plan: 'basic',
          description,
          expirationAccountTrial: dayjs(expirationDate).format('YYYY-MM-DD'),
          disclaimer,
          website,
          brandingColors: brandingColor,
          address: address || '',
          logo: logo || '',
        }, () => props.close()));
    }
    else{
      if (props.organization?.id === '5e293897a425972f28446fd7'){
        toast('It is not allowed to make changes in this company');
        return;
      }
      if (plan === 'None'){
        toast('Please enter a Plan!');
        return; 
      }
      if (companyName === ''){
        toast('Please enter a company name!');
        return;
      }
      if (!expirationDate){
        toast('Please Enter a Expiration date!');
        return;
      }
    }
  }

  const reset = () => {
    if (!props.organization?.id){
      setCompanyName('');
      setDescription('');
      setAddress('');
      setDisclaimer('');
      setExpirationDate('');
      setWebsite('');
      setLogo('');
      setBrandingColor(DataPalettesDefault);
    }
    else{
      setCompanyName(props.organization?.name || '');
      setPlan(props.organization?.plan ? props.organization?.plan : 'None');
      setDescription(props.organization?.customization?.texts?.about || '');
      setDisclaimer(props.organization?.customization?.texts?.disclaimer || '');
      setAddress(props.organization?.address);
      setExpirationDate(props.organization?.expirationAccountTrial || new Date().toString())
      setWebsite(props.organization?.website);
      setBrandingColor(props.organization?.customization?.colors  || DataPalettesDefault);
      setLogo(props.organization?.logo);
    }
  }

  useEffect(() => {
    reset();
    console.info(props.organization?.name);
  }, [props.organization]);

  const openModalPalette = (paletteSelected: string) => {
    setShowModalPalette(true);
    setColorPalette(paletteSelected);
  }

  return (
    <div className='content-user-organization-section'>
      <div className='header-company'>
          <div className='name-company'>{props.organization?.name || 'Untitled Company'}</div>
          <div className='plan'>
            Plan: 
            <div 
              className={`plan-button ${namePlan(plan)}`}
              onClick={() => setShowModalPlan(true)}
            >
              {namePlan(plan)}
            </div>
          </div>
          <div className='expiration-date'>
            Expiration Date:
            <DatePickerInput
              onChange={(date) => setExpirationDate(date.toString())}
              displayFormat="YYYY-MM-DD"
              showOnInputClick
              value={expirationDate || new Date()}
              className="bita-calendar-date"
            />
          </div>
          <div className='content-buttons'>
            <Button onClick={save}>Save</Button>
            <Button secondary onClick={reset}>Reset</Button>
            <Button secondary onClick={props.close}>Close</Button>
          </div>
      </div>
      <div className={`content-company-data ${props.organization?.id === '5e293897a425972f28446fd7' ? 'disabled' : ''}`}>
        <div>COMPANY DATA</div>
        <div className='form-company'>
          <div className='row'>
            <div className='label'>COMPANY NAME</div>
            <input
              type="text"
              placeholder="Enter Company Name. "
              value={companyName}
              onChange={(value)=> setCompanyName(value.target.value)}
            />
          </div>
          <div className='row'>
            <div className='label'>DESCRIPTION</div>
            <textarea
              value={description}
              placeholder="Insert company description here...."
              onChange={evt => setDescription(evt.target.value)}
              maxLength={800}
              rows={8}
            />
            <div className='characters-left'>Characters left: {800 - description.length}</div>
          </div>
          <div className='row'>
            <div className='label'>ADDRESS</div>
            <input
              type="text"
              placeholder="Carrer Chapi 31, Piso 1. Barcelona, Spain. "
              value={address}
              onChange={(value)=> setAddress(value.target.value)}
            />
          </div>
          <div className='row'>
            <div className='label'>WEBSITE</div>
            <input
              type="text"
              placeholder="WWW.Organizationwebsite.com"
              value={website}
              onChange={(value)=> setWebsite(value.target.value)}
            />
          </div>
          <div className='row'>
            <div className='label'>DISCLAIMER</div>
            <textarea
              value={disclaimer}
              placeholder="Insert disclaimer of company...."
              onChange={evt => setDisclaimer(evt.target.value)}
              maxLength={1800}
              rows={8}
            />
            <div className='characters-left'>Characters left: {1800 - disclaimer.length}</div>
          </div>
          <div className='row'>
            <div className='label'>Custom Branding</div>
            <div className='custom-branding'>
              <div className='content-colors-palette'>
                <div>Company Colors</div>
                <div>Palette</div>
                <div className="branding-palette-container">
                  {Object.entries(brandingColor || DataPalettesDefault).map((option, key) => 
                    DataPalettesDefault[option[0]]
                      && <PaletteItem 
                      title={option[0]}
                      id={`palette-${key}-${option[1]}-${props.organization?.id}`}
                      key={`palette-${key}-${option[1]}-${props.organization?.id}`}
                      value={`${option[1]}`|| '#fff'}
                      onClick={() => openModalPalette(option[0])}   
                    />    
                  )}
                </div>
                <Button 
                  secondary 
                  width='70px'
                  onClick={() =>setShowModalPalette(true)}   
                >
                  Edit
                </Button>
              </div>
              <div className='content-logo-preview'>
                <div>Logo</div>
                <div>Preview</div>
                <div className='content-preview'>
                  <div className='preview' >
                    {logo !== '' && <img src={logo} alt=""></img>}
                    </div>
                  <Button width='70px' onClick={() => setShowModalLogo(true)}>Upload</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          props.organization?.id  &&
            <UserListComponent 
              organization={props.organization}
              listOrganization={props.listOrganization}
              listCharges={props.listCharges}
            /> 
        }
        {
          props.organization?.id && <Button className='save-button-bottom' onClick={save}>Save</Button>
        }
      </div>
      <BrandingLogoModal 
        showModal={showModalLogo} 
        closeModal={() => setShowModalLogo(false)}   
        logo={logo}
        confirm={(value: Blob) => {
          let reader = new FileReader();
          reader.readAsDataURL(value);
          reader.onloadend = function() {
            var base64data = reader.result;                
            base64data && setLogo(base64data);
          }
        }}  
      />
      <BrandingPaletteModal
        showModal={showModalPalette}
        closeModal={() => setShowModalPalette(false)}  
        palettes={brandingColor || DataPalettesDefault } 
        paletteSelected={colorPalette}
        confirm={(value: {title: string, color: string}) => {
        setBrandingColor({ ...brandingColor, [value.title]: value.color })
        }}
      />
      <SelectedPlanModal 
        showModal={showModalPlan} 
        closeModal={() => setShowModalPlan(false)} 
        organization={props.organization}
        listOrganization={props.listOrganization}
        plans={props.plans}
        confirm={(valuePlan: string, valueOrganization) => {
          if(valueOrganization === '' || valueOrganization === props.organization?.id) {
            if (valuePlan){
              setPlan(valuePlan);
            }
          }
        }}
      />
    </div>
  );
};

CreateUserOrgSection.defaultProps = {
  organization: {
    plan: 'None' },
};


export default CreateUserOrgSection;