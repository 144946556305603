import React from 'react';
import { useLocation } from 'react-router-dom';
import { menuData } from '../../constant/Menu';
import './Topbar.scss';

const Topbar = () => {
  const location = useLocation();
  return (
    <div className='topbar'>
      {menuData.find(item => item.link === `/${location.pathname.split('/')[1]}`)?.textTopBar}
    </div>
  );
}

export default Topbar;
