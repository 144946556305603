import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { SimpleThunk } from '../types/SimpleTunk';
import Axios from '../utils/Axios';
import { Login, User } from '../types/User';

const initialStateUser: User = {};

const user = createSlice({
  name: 'user',
  initialState: initialStateUser,
  reducers: {
    addUser(state, action: PayloadAction<User>) {
      return { ...state, ...action.payload };
    },
    logout: (state, action: PayloadAction<string>) => initialStateUser,
  },
});

export const login = (dataLogin: Login): SimpleThunk => async (dispatch) => {
  console.info('post /login');
  Axios.post('/login', dataLogin)
  .then((rsp) => {
    dispatch(user.actions.addUser({ ...rsp.data.user, token: rsp.data.token }));
    toast.dismiss();
    toast.success(`Welcome ${dataLogin.user}!`);
    if (rsp.data.token && Axios.setToken){
      Axios.setToken(rsp.data.token);
    }
  })
  .catch((err) => {
    console.info(err);
    toast.dismiss();
    toast.error('Error', { autoClose: 3000 });
  });
};

export default user;
