import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import { customStylesContent } from './CustomStyle';
import './Modal.scss';

const Modal = (props: { 
  isOpen: boolean, 
  setModalState: (value: boolean) => void, 
  children: ReactNode, 
  background?: boolean 
}) => {
  const customStyles = {
    content: customStylesContent,
    overlay: {
      backgroundColor: props.background ? 'rgba(78,78,78, 0.75)' : 'transparent',
    },
  };
  return (
    <ReactModal
      style={customStyles}
      isOpen={props.isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => props.setModalState(false)}
    > 
     <div className='modal'>
      <div className='close' onClick={() => props.setModalState(false)}>&times;</div>
        <div className='content'>
          {props.children}
        </div>
     </div>
    </ReactModal>
  );
};

Modal.defaultProps = {
  background:false,
};


export default Modal;