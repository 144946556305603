import { snakeCase } from "lodash";
import React, { useState } from "react";
import { ReactComponent as ColorEditIcon } from '../../static/icon/edit_color.svg';
import { ReactComponent as ColorEditBlackIcon } from '../../static/icon/edit_color_black.svg';


const PaletteItem = (props : { id: string, value: string,  title: string, onClick: () => void, }) => {
    const [color] = useState(props.value);
    return (
      <div className="palette-option-container" key={props.id} onClick={() => props.onClick()}>

        <div
          className="paletteColor"
          style={{ backgroundColor: `${color || '#fff'}` }}
        >
            {color?.toLowerCase() === '#ffffff' ? (
              <ColorEditBlackIcon className="colorEditIcon" />
              ) : (
              <ColorEditIcon className="colorEditIcon" />
            )}
          <div className="dot" style={{ background: color?.toLowerCase() === '#ffffff' ? '#000000' : "" }}/>
        </div>
        <span>{snakeCase(props.title).replace('_', ' ')}</span>
      </div>
    );
  };

export default PaletteItem