import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Common {
  header: string;
}

const initialState: Common = {
  header: 'HOME'
};

const common = createSlice({
  name: 'common',
  initialState:initialState,
  reducers: {
    addHeader(state, action: PayloadAction<string>) {
      return { ...state, header: action.payload };
    },
  },
});

export default common;
