export const colors = {
  gradient_left: "#2962FF",
  gradient_right: "#11259e",
  header: "#FFFFFF",
  list_background: "#C2D0F7",
  primary_chart: "#2962FF",
  primary_header: "#2962FF",
  quaternary_chart: "#BFBFBF",
  secondary_chart: "#5981ED",
  secondary_header: "#5981ED",
  separator: "#2962FF",
  tertiary_chart: "#FA5400",
  tertiary_header: "#616870",
  text: "#000000",
}